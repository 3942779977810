import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Row, Col } from 'reactstrap';

import { Layout, SEO, Image } from '../components';
import messages from '../messages/partners';
import partner1 from '../assets/images/MFA.png';
import partner2 from '../assets/images/SIDA.png';
import partner3 from '../assets/images/undp-logo.png';
import partner4 from '../assets/images/SwahiliPot.png';
import partner5 from '../assets/images/logo_ptf_british_council.png';

const Partners = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title={intl.formatMessage(messages.partners)} />
      <div className="partners-main-container">
        <Row>
          <Col md="9">
            <div className="partners-title mb-5">
              {intl.formatMessage(messages.bePartners)}
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="partners-description">
            {intl.formatMessage(messages.introTextLeft)}
          </Col>
          <Col className="partners-description">
            {intl.formatMessage(messages.introTextRight)}
          </Col>
        </Row>
        <Row>
          <Col className="partners-sub-title mb-3 mt-4">
            {intl.formatMessage(messages.ourPartners)}
          </Col>
        </Row>
        <Row>
          <Col className="partners-display mb-5">
            <div className="d-flex flex-wrap align-items-center">
              <Image src={partner1} alt="MFA" className="mr-4 mb-5" />
              <Image src={partner2} alt="SIDA" className="mr-4 mb-5" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="partners-sub-title mb-3 mt-5">
            {intl.formatMessage(messages.techTBC)}
          </Col>
        </Row>
        <Row>
          <Col className="partners-display mb-5">
            <div className="d-flex flex-wrap align-items-center">
              <Image src={partner1} alt="MFA" className="mr-4 mb-5" />
              <Image src={partner2} alt="SIDA" className="mr-4 mb-5" />
              <Image src={partner3} alt="UNDP" className="mr-4 mb-5" />
              <Image src={partner4} alt="SWAHLIPOT" className="mr-4 mb-5" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="partners-sub-title mb-3 mt-5">
            {intl.formatMessage(messages.contentTBC)}
          </Col>
        </Row>
        <Row>
          <Col className="partners-display mb-5">
            <div className="d-flex flex-wrap align-items-center">
              <Image
                src={partner5}
                alt="British Council"
                className="mr-4 mb-5"
              />
              <Image src={partner4} alt="SWAHLIPOT" className="mr-4 mb-5" />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
export default Partners;
