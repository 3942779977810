import { defineMessages } from 'react-intl';

export const scope = 'partners';

export default defineMessages({
  partners: {
    id: `${scope}.partners`,
    defaultMessage: 'Partners'
  },
  bePartners: {
    id: `${scope}.bePartners`,
    defaultMessage: 'Be an AFRICAN CROSSROADS PARTNERS'
  },
  introTextLeft: {
    id: `${scope}.introTextLeft`,
    defaultMessage:
      'Hear that? It’s almost as if thousands of spooks and hackers suddenly cried out at once… The Internet Engineers Task Force has just unanimously approved a security framework that will make encrypted connections on the web faster and more resistant to snooping.'
  },
  introTextRight: {
    id: `${scope}.introTextRight`,
    defaultMessage:
      'Hear that? It’s almost as if thousands of spooks and hackers suddenly cried out at once… The Internet Engineers Task Force has just unanimously approved a security framework that will make encrypted connections on the web faster and more resistant to snooping.'
  },
  ourPartners: {
    id: `${scope}.ourPartners`,
    defaultMessage: 'Our Partners'
  },
  techTBC: {
    id: `${scope}.techTBC`,
    defaultMessage: 'Technical Partners TBC'
  },
  contentTBC: {
    id: `${scope}.contentTBC`,
    defaultMessage: 'Content Partners TBC'
  }
});
